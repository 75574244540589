<template>
  <div class="fill_in">

    <div class="Detailed_statement_title">
      补充投诉<span>＊</span>
    </div>

    <div class="Object_complaint N_Object_complaint">
      <div class="Object_complaint_left">
        <span>补充投诉详情</span>
        <span>＊</span>
      </div>
      <div class="Object_complaint_right">
        <textarea name="" id="" class="textarea" cols="30" v-model="Input_Value" placeholder="请详细阐述不少于20字的投诉事由"
          rows="10"></textarea>
      </div>
    </div>
    <div class="Evidential_materials">
      补充证据材料<img src="@/assets/img/xingzhuang7.png" alt="">
    </div>
    <el-upload class="Max_Wdith" action="https://tsadmin.qudong.com/ApiUpload/upload_many" :headers="headers"
      :before-upload="beforeRef" :on-success="successAgreement" multiple name="file[]" list-type="picture-card"
      :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <div class="be_careful">
      <div>注:</div>
      <div>1. 请依据事实投诉，禁止上传含有违反国家法律法规及侮辱、谩骂、诋毁、侵害他人隐私的文字、图片和视频内容。 </div>
      <div>2. 标有*号处为必填项，标有<img style="display:inline-block;width:14px;height:16px;"
          src="@/assets/img/xingzhuang7.png" />为隐私项仅投诉人、被诉人、平台三方可见。</div>
    </div>

    <div class="Submit" @click="Submit">提交</div>
  </div>
</template>

<script>
import { complaint_update } from "../../api/api.js";

let token = localStorage.getItem("token");
let url1 = ""; //传多图
export default {
  metaInfo(){
     return{
        title: '补充投诉',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  data() {
    return {
      headers: {
        token: token,
      },
      dialogImageUrl: "",
      dialogVisible: false,
      url1: "",
      items: {}, //上个页面带过来的值
      modify: {}, //上个页面带来的里面的对象
      Input_Value: "", //输入框
    };
  },
  methods: {
    // 图片上传操作前判断，限制大小
    beforeRef(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG || !isLt2M) {
        this.$message.error("上传文件只能是图片格式，且大小不能超过2M");
      }
      return isJPG, isLt2M;
    },

    // 请求图片成功
    successAgreement(res) {
      // //console.log(res);
      url1 = url1.concat(res.data + ",");
      this.url1 = url1;
      //console.log(url1);
    },
    // 删除图片
    handleRemove(file, fileList) {
      //console.log(file, fileList);
    },
    // 预览图片
    handlePictureCardPreview(file) {
      // //console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 提交
    Submit() {
      let Obj_id = this.items.id;
      let Input_Value = this.Input_Value;
      let url1 = this.url1;
      //console.log(url1);
      //console.log(this.Input_Value);
      //console.log(this.items.id);
        if (!Input_Value) {
          this.$message.error("投诉详情不能为空");
        } else {
            complaint_update({
              id: Obj_id,
              complaint_details_supplement: Input_Value,
              evidential_materials_supplement: url1,
            }).then((res) => {
              //console.log(res);
              if (res.data.code == 200) {
                this.$message.success(res.data.msg);
                setTimeout("location.href='/'", 1000);
              } else {
                this.$message.error(res.data.msg);
              }
            });
        }
    },

  },

  mounted() {
    this.items = JSON.parse(this.$route.query.item);
  },
};
</script>

<style scoped>
.fill_in {
  width: 940px;
  background: #fff;
  margin-left: 20px;
  padding: 30px 0px 40px 40px;
}
.essential_information_title {
  font-size: 22px;
}
.ipt {
  width: 540px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  padding-left: 15px;
}
.Object_complaint {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.Object_complaint_left {
  width: 120px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.Object_complaint_left img {
  width: 14px;
  height: 16px;
}
.Object_complaint_rights {
  position: relative;
}

.Object_complaint_left span:nth-child(1) {
  color: black;
}
.Object_complaint_left span:nth-child(2) {
  color: #ff4a4a;
}
.ipt_num {
  width: 100px;
  height: 40px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding-left: 15px;
}
.Detailed_statement_title {
  font-size: 22px;
}
.Detailed_statement_title span {
  color: #ff4a4a;
}
.N_Object_complaint {
  align-items: end;
}
.textarea {
  width: 540px;
  height: 240px;
  resize: none;
  padding-left: 15px;
  border: 1px solid #e8e8e8;
  padding-top: 10px;
}
.Evidential_materials {
  font-size: 22px;
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.Evidential_materials img {
  width: 14px;
  height: 16px;
}
>>> .Max_Wdith .el-upload--picture-card {
  background: url(../../assets/img/upload_img.png) no-repeat center;
  border: none;
}
>>> .Max_Wdith1 .el-upload--picture-card {
  background: url(../../assets/img/upload_img.png) no-repeat center;
  border: none;
}
>>> .el-icon-plus {
  display: none;
}
.Max_Wdith {
  width: 600px;
  margin-left: 115px;
  margin-top: 20px;
  margin-bottom: 40px;
}
.Max_Wdith1 {
  width: 600px;
  margin-left: 115px;
  margin-top: 20px;
  margin-bottom: 40px;
}
>>> .el-upload-list__item {
  margin-right: 30px;
}
.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}
.be_careful {
  margin-left: 115px;
  width: 580px;
  color: #666666;
  font-size: 16px;
}
.Submit {
  width: 160px;
  height: 48px;
  background: #1f72d9;
  border-radius: 10px;
  color: #fefeff;
  font-size: 18px;
  text-align: center;
  line-height: 48px;
  margin: 50px auto 0 auto;
  margin: 50px 0px 0px 305px;
  cursor: pointer;
}

/* 投诉对象被激活 */
.focus_on {
  width: 500px;
  height: 206px;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  position: absolute;
  background: #ffffff;
  top: 50px;
}
.focus_on_top {
  font-size: 18px;
  color: #333333;
  margin-left: 10px;
  margin-top: 20px;
}
.focus_on_bottom {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  padding-right: 50px;
}
.focus_on_bottom li {
  border: 1px solid #999999;
  border-radius: 18px;
  margin-left: 25px;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
}
/* 取消 */
.cancel {
  position: absolute;
  right: 65px;
  top: 11px;
  color: #999999;
  font-size: 14px;
  cursor: pointer;
}
/* 放大镜 */
.magnifier {
  position: absolute;
  right: 0;
  top: 1px;
  width: 50px;
  height: 40px;
  background: #d9d9d9;
}
.magnifier img {
  width: 18px;
  height: 18px;
  margin: 11px auto;
}
.Input_List {
  position: absolute;
  top: 50px;
}
.Input_Lists {
  width: 491px;
  height: 40px;
  border: 1px solid #e8e8e8;
  line-height: 40px;
  padding-left: 15px;
  cursor: pointer;
  background: #fff;
}
.Input_Listss {
  width: 491px;
  height: 40px;
  border: 1px solid #e8e8e8;
  line-height: 40px;
  padding-left: 15px;
  cursor: pointer;
  background: #e8e8e8;
  display: flex;
  justify-content: space-between;
}
.Input_Listss img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.Input_Listss div:nth-child(2) {
  display: flex;
  align-items: center;
  margin-right: 15px;
  color: #1f72d9;
}
</style>